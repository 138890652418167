<template>
  <auth-layout>
    <!-- Forgot password-->
    <b-col lg="5" class="d-flex align-items-center auth-bg px-2 p-lg-5">
      <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
        <b-card-title class="mb-1"> Verify Account </b-card-title>
        <b-card-text class="mb-2">
          Enter the verification codes sent to your phone and email
        </b-card-text>

        <!-- Phone Verification Code-->
        <validation-observer ref="verifyAccountForm" #default="{ invalid }">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="verifyAccount"
          >
             <!-- first name -->
              <b-form-group label-for="email" label="Email Verification Code">
                <validation-provider
                  #default="{ errors }"
                  name="Email Verification Code"
                  vid="email"
                  rules="required"
                >
                  <b-form-input
                    id="email"
                    v-model.trim="email"
                    name="email"
                    placeholder="XXXXX"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

               <!-- Phone Verification Code-->
              <b-form-group label-for="phone" label="Phone Verification Code">
                <validation-provider
                  #default="{ errors }"
                  name="Phone Verification Code"
                  vid="phone"
                  rules="required"
                >
                  <b-form-input
                    id="phone"
                    v-model.trim="phone"
                    name="phone"
                    placeholder="XXXXX"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

            <b-button type="submit" variant="primary" block class="d-flex align-items-center justify-content-center" :disabled="invalid || formIsLoading">
              <b-spinner small v-if="formIsLoading" />
              &nbsp;
              Verify Account
            </b-button>
          </b-form>
        </validation-observer>

        <p class="text-center mt-2">
          <b-link @click="sendVerificationCode">
            Resend Verification Codes
          </b-link>
        </p>
      </b-col>
    </b-col>
    <!-- /Forgot password-->
  </auth-layout>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import AuthLayout from "@/layouts/auth/AuthLayout.vue";
import {
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import { required, email } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    AuthLayout,
    BCol,
    BLink,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  props:["id"],
  data() {
    return {
      email: "",
      phone: "",
      formIsLoading:false,
      // validation
      required,
      email,
    };
  },
  methods: {
    verifyAccount() {
      this.formIsLoading = true;
      this.$refs.verifyAccountForm.validate().then(success => {
        if (success) {
          this.$http.post('/verify-account',{
              phone_code:this.phone,
              email_code:this.email,
              user_id:this.id,
            })
            .then(response => {
              this.formIsLoading = false;
              this.$router
                .push({ 
                  name: 'login',
                })
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: response.data.message,
                      icon: "CheckIcon",
                      variant: "success",
                      text: 'Your account has been verified. Kindly wait until the admin approves your account.',
                    },
                  });
                });
            })
            .catch(error => {
               for (let err of error.response.data.error) {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title:err,
                      icon: "AlertCircleIcon",
                      variant: "danger",
                    },
                  });
                }
                this.formIsLoading = false;
            })
        }
      })
    },
    sendVerificationCode(){
      this.formIsLoading = true;
      this.$http.post('/send-verification-code',{
        id:this.id,
      })
      .then(response => {
        this.formIsLoading = false;
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Verification Codes Resend`,
            icon: "CheckIcon",
            variant: "success",
            text: response.data.message,
          },
        });
      })
      .catch(error => {
        for (let err of error.response.data.error) {
          this.resError = err;
        }
        this.formIsLoading = false;
      })
    }
  },
};
</script>
